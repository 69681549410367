import { lang } from '../config'

export const generateMetaDescriptionFromRichText = ({ richText, title }) => {
  let blocksMetaDescription = ''
  if (
    richText &&
    richText.json &&
    richText.json.content[0] &&
    richText.json.content[0].data &&
    richText.json.content[0].data.target &&
    richText.json.content[0].data.target.fields
  ) {
    const {
      headline: heroHeadline,
      subhead: heroSubhead,
    } = richText.json.content[0].data.target.fields

    // Add hero headline to meta description
    if (heroHeadline && heroHeadline[lang]) {
      const headline = heroHeadline[lang]
      if (headline !== title) {
        blocksMetaDescription += headline
      }
    }

    // Add hero subhead to meta description
    if (heroSubhead && heroSubhead[lang]) {
      const subhead = heroSubhead[lang]

      // Add divider
      if (blocksMetaDescription.length) {
        if (blocksMetaDescription.endsWith('. ')) {
          blocksMetaDescription += subhead
        } else if (blocksMetaDescription.endsWith('.')) {
          blocksMetaDescription += ` ${subhead}`
        } else {
          blocksMetaDescription += `. ${subhead}`
        }
      } else {
        blocksMetaDescription += subhead
      }
    }
  }

  return blocksMetaDescription
}
