import React from 'react'
import styled from '@emotion/styled'

const PageTitle = props => {
  return (
    <Title
      small={props.small}
      className={props.hidden ? 'visually-hidden' : ''}
    >
      {props.children}
    </Title>
  )
}

export default PageTitle

const Title = styled.h1`
  margin: 30px 0;
  margin-bottom: 60px;
  text-align: center;
`
