/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:5ddaed5d-b1fb-40e4-956e-356e1ae76acc",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_KJLGtsON5",
    "aws_user_pools_web_client_id": "2qbq49rjmv0r96nv1mmmavr4nb",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://1ysxa48780.execute-api.us-east-2.amazonaws.com/dev",
            "region": "us-east-2"
        }
    ]
};


export default awsmobile;
