import React from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import Layout from '../components/Layout'
import Container from '../components/Container'
import PageTitle from '../components/PageTitle'
import PageBody from '../components/PageBody'
import SEO from '../components/SEO'
import { renderBlocks } from '../components/blocks'
import Amplify from 'aws-amplify'
import awsmobile from '../aws-exports'
import { generateMetaDescriptionFromRichText } from '../utils/generateMetaDescriptionFromRichText'
Amplify.configure(awsmobile)

const PageTemplate = ({ data }) => {
  const {
    title,
    metaDescription,
    slug,
    body,
    childContentfulPageContentRichTextNode,
  } = data.contentfulPage

  let pageSlug = slug.replace('/', '')
  if (pageSlug === '') {
    pageSlug = 'home'
  }

  return (
    <SeamlessLayout className={`page-${pageSlug}`} simplePage={body}>
      <SEO
        title={title}
        description={
          metaDescription
            ? metaDescription.internal.content
            : body &&
              body.childMarkdownRemark &&
              body.childMarkdownRemark.excerpt
            ? body.childMarkdownRemark.excerpt
            : generateMetaDescriptionFromRichText({
                richText: childContentfulPageContentRichTextNode,
                title,
              })
        }
      />
      <SeamlessContainer>
        <PageTitle hidden={!body}>{title}</PageTitle>
        {body && <PageBody body={body} />}
        {childContentfulPageContentRichTextNode && (
          <RichContentContainer>
            {renderBlocks(
              childContentfulPageContentRichTextNode?.json || {
                content: [],
                data: {},
                nodeType: 'document',
              }
            )}
          </RichContentContainer>
        )}
      </SeamlessContainer>
    </SeamlessLayout>
  )
}

export const query = graphql`
  query($slug: String!) {
    contentfulPage(slug: { eq: $slug }) {
      title
      slug
      metaDescription {
        internal {
          content
        }
      }
      body {
        childMarkdownRemark {
          html
          excerpt(pruneLength: 320)
        }
      }
      childContentfulPageContentRichTextNode {
        json
      }
    }
  }
`

export default PageTemplate

const SeamlessLayout = styled(Layout)`
  padding-top: 0;
  padding-bottom: 0;
`

const SeamlessContainer = styled(Container)`
  padding-top: 0;
  padding-bottom: 0;
`

const RichContentContainer = styled.div`
  margin-top: -144px;
  padding-top: 144px;

  & p:empty:last-child {
    margin-bottom: 0;
  }
`
